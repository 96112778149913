import * as React from 'react';

import Spacer from 'components/atoms/Spacer';
import type { Relationship } from 'components/combi-search/types';

import cs from './index.scss';
import ItemsRow from '../molecules/ItemsRow';

type Props = {
  relationships: Relationship[];
  selectedRelationship: Relationship | null;
  onSelect: (relationship: Relationship | null) => void;
};

const RelationshipSelector: React.FC<Props> = React.memo(({ relationships, selectedRelationship, onSelect }) => {
  const [relationships1, relationships2] = React.useMemo(() => [relationships.filter((_, i) => i % 2 === 0), relationships.filter((_, i) => i % 2 === 1)], [relationships]);

  const onSelectRelationship = React.useCallback(
    (selected: Relationship) => {
      onSelect(selected);
    },
    [onSelect]
  );

  return (
    <div className={cs['container']}>
      <div className={cs['overflow']}>
        <ItemsRow items={relationships1} selected={selectedRelationship} onSelect={onSelectRelationship} />
        <Spacer size={16} />
        <ItemsRow items={relationships2} selected={selectedRelationship} onSelect={onSelectRelationship} />
      </div>
    </div>
  );
});

RelationshipSelector.displayName = 'RelationshipSelector';

export default RelationshipSelector;
