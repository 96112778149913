import * as React from 'react';
import { Color } from 'libs/colors';

export type IconProps = {
  color?: Color;
  size?: string;
  fill?: boolean;
  strokeWidth?: string;
  className?: string;
};

// Iconコンポーネントを生成する
// Iconコンポーネントは、別途生成するSVG Spriteを利用している
// (参照: https://gracia.esa.io/posts/1632）
//
// icon引数にはfeather iconのidを渡す
// https://feathericons.com/
const feather = (icon: string): React.FC<IconProps> => {
  const component: React.FC<IconProps> = React.memo(({ color, size, fill, strokeWidth, className }) => {
    const style: React.CSSProperties = {};
    if (color) {
      style.stroke = color.hex;
    }
    if (fill) {
      style.fill = 'currentColor';
    }
    if (strokeWidth) {
      style.strokeWidth = strokeWidth;
    }
    if (size) {
      style.width = size;
      style.height = size;
    }

    return (
      <svg className={`feather-icon ${className}`} style={style}>
        <use xlinkHref={`/img/icons.svg#icon-${icon}`} />
      </svg>
    );
  });
  component.displayName = `${toPascalCase(icon)}Icon`;
  return component;
};

const toPascalCase = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1).replace(/[-_](.)/g, (match, group1) => group1.toUpperCase());

export const ChevronDownIcon = feather('chevron-down');
export const ChevronRightIcon = feather('chevron-right');
export const ChevronLeftIcon = feather('chevron-left');
export const FilterIcon = feather('filter');
export const GiftIcon = feather('gift');
export const SearchIcon = feather('search');
export const TagIcon = feather('tag');
export const UserIcon = feather('user');
export const MapPinIcon = feather('map-pin');
export const CarretDownIcon = feather('carret-down');
export const BellIcon = feather('bell');
export const ClockIcon = feather('clock');
export const HelpCircle = feather('help-circle');
