import * as React from 'react';

import type { Age } from 'components/combi-search/types';
import cs from './index.scss';

type SelectAgeItemProps = {
  availables: Age[];
  selected: Age | null;
  onSelect: (age: Age) => void;
};

const SelectAgeItem: React.FC<SelectAgeItemProps> = React.memo(({ availables, selected, onSelect }) => {
  const options = React.useMemo(
    () =>
      availables.map((age) => (
        <option key={age.name} value={age.name}>
          {age.name}
        </option>
      )),
    [availables]
  );

  const onSelectAge = React.useCallback(
    (age) => {
      const result = availables.filter((available) => available.name === age);
      onSelect(result[0]);
    },
    [availables, onSelect]
  );

  return (
    <div>
      <select value={selected?.name} onChange={(e) => onSelectAge(e.target.value)} className={cs['selector']}>
        <option value={undefined}>指定なし</option>
        {options}
      </select>
    </div>
  );
});

SelectAgeItem.displayName = 'SelectAgeItem';

export default SelectAgeItem;
