import * as React from 'react';

import * as colors from 'libs/colors';
import { genPublicUrl } from 'libs/cloudinary';
import Paragraph from 'components/atoms/Paragraph';
import Spacer from 'components/atoms/Spacer';
import LazyImage from 'components/atoms/LazyImage';

import cs from './index.scss';

type Item = {
  name: string;
  imageUrl: string;
};

type ItemCellProps = {
  item: Item;
  selected: boolean;
  onSelected: () => void;
};

const ItemCell: React.FC<ItemCellProps> = React.memo(({ item, selected, onSelected }) => {
  const classNames = [cs['image-container']];
  if (selected) classNames.push(cs['selected']);
  const className = classNames.join(' ');
  return (
    <div className={cs['item-cell']} onClick={onSelected}>
      <div className={className}>
        <LazyImage src={genPublicUrl(item.imageUrl, 50 * 4)} width="50px" height="50px" fit="cover" />
      </div>
      <Spacer size={4} />
      <Paragraph align="center" full size="12px" color={selected ? colors.softOrange : colors.gray90} bold={selected ? true : false}>
        {item.name}
      </Paragraph>
    </div>
  );
});

ItemCell.displayName = 'ItemCell';

export default ItemCell;
