import * as React from 'react';

import * as colors from 'libs/colors';
import { genPublicUrl } from 'libs/cloudinary';
import { merge } from 'libs/classname';
import Spacer from 'components/atoms/Spacer';
import Divider from 'components/atoms/Divider';
import LazyImage from 'components/atoms/LazyImage';
import { ChevronRightIcon } from 'components/icons';
import { CombiSearchContext } from 'components/combi-search/Context';
import type { SelectedCategory, ParentCategory, ChildCategory, ProductGroup } from 'components/combi-search/types';

import cs from './index.scss';
import SelectCircle from '../SelectCircle';

type Props = {
  selected: SelectedCategory | null;
  onSelect: (selected: SelectedCategory | null) => void;
};

const CategorySelector: React.FC<Props> = React.memo(({ selected, onSelect }) => {
  const { parents } = React.useContext(CombiSearchContext);

  const onClickParent = React.useCallback(
    (parent) => {
      // parentの選択条件を保存する
      onSelect({ parent, category: null, productGroup: null });
    },
    [onSelect]
  );

  const onClickCategory = React.useCallback(
    (parent, category) => {
      // parentとcategoryの選択条件を保存する
      onSelect({ parent, category, productGroup: null });
    },
    [onSelect]
  );

  const onSelectChild = React.useCallback(
    (parent, category, productGroup) => {
      onSelect({ parent, category, productGroup });
    },
    [onSelect]
  );

  return (
    <div className={cs['container']}>
      <div className={cs['parent-container']}>
        {parents.map((parent) => (
          <React.Fragment key={parent.name}>
            <ParentItem isSelected={selected?.parent === parent} parent={parent} onClick={onClickParent} />
          </React.Fragment>
        ))}
      </div>

      {/* parentが選択されているときは常にcategory sectionを表示する
        parentが選択されていないのは何も選択されていない初期状態のときのみ */}
      {selected !== null && <CategorySection selected={selected} parent={selected.parent} onClickCategory={onClickCategory} />}

      {/* categoryが選択されているときは常にchild sectionを表示する
        categoryが選択されていないのは何も選択されていない初期状態のとき、もしくはparentだけが選択されているとき
        実装上、categoryは選択されているがparentは選択されていない、みたいな状況は発生しない */}
      {selected !== null && selected.category !== null && <ChildCategorySection selected={selected} parent={selected?.parent} category={selected?.category} onSelectChild={onSelectChild} />}
    </div>
  );
});

CategorySelector.displayName = 'CategorySelector';

type ParentItemProps = {
  isSelected: boolean;
  parent: ParentCategory;
  onClick: (parent: ParentCategory) => void;
};

const ParentItem: React.FC<ParentItemProps> = React.memo(({ isSelected, parent, onClick }) => {
  const onClickParent = React.useCallback(() => onClick(parent), [parent, onClick]);

  return (
    <div className={merge(cs['parent-section-container'], isSelected ? cs['bg-linen'] : null)} onClick={onClickParent}>
      <div className={cs['box-item']}>
        <div className={cs['parent-image-container']}>
          <LazyImage src={genPublicUrl(parent.imageUrl, 24 * 4)} width="25px" height="25px" fit="contain" />
        </div>
      </div>
      <Spacer size={8} />
      <div className={cs['parent-name']}>{parent.name}</div>
      <Spacer size={16} />
      <ChevronRightIcon size="30px" color={colors.gray50} />
    </div>
  );
});

ParentItem.displayName = 'ParentItem';

type CategoryProps = {
  selected: SelectedCategory;
  parent: ParentCategory;
  onClickCategory: (parent: ParentCategory, category: ChildCategory | null) => void;
};

const CategorySection: React.FC<CategoryProps> = React.memo(({ selected, parent, onClickCategory }) => {
  // categoryがnullのときは`全てのcategory.name`を選択していることと同義
  // なので全ての~にデフォルトで色を付けておく
  const isCategoryNull = selected?.category === null;
  const SelectAll = React.useCallback(() => {
    onClickCategory(parent, null);
  }, [parent, onClickCategory]);
  const onClickCategoryItem = React.useCallback(
    (category) => {
      onClickCategory(parent, category);
    },
    [onClickCategory, parent]
  );

  return (
    <div className={cs['category-container']}>
      <div className={merge(cs['category-all'], isCategoryNull ? cs['bg-linen'] : null)} onClick={SelectAll}>
        <p>{`全ての${parent?.name}`}</p>
        <SelectCircle isSelected={isCategoryNull} />
      </div>
      <Divider bg={colors.gray20} />
      {parent?.categories.map((category) => (
        <CategoryItems isSelected={selected?.category === category} category={category} onClick={onClickCategoryItem} key={category.name} />
      ))}
    </div>
  );
});

CategorySection.displayName = 'CategorySection';

type CategoryItemsProps = {
  isSelected: boolean;
  category: ChildCategory;
  onClick: (category: ChildCategory) => void;
};

const CategoryItems: React.FC<CategoryItemsProps> = React.memo(({ isSelected, category, onClick }) => {
  const onClickCategory = React.useCallback(() => onClick(category), [category, onClick]);

  return (
    <div className={merge(cs['category-item-container'], isSelected ? cs['bg-linen'] : null)} onClick={onClickCategory}>
      <p>{category.name}</p>
      <ChevronRightIcon size="30px" color={colors.gray50} />
    </div>
  );
});

CategoryItems.displayName = 'CategoryItems';

type ChildCategorySectionProps = {
  selected: SelectedCategory;
  parent: ParentCategory;
  category: ChildCategory;
  onSelectChild: (parent: ParentCategory, category: ChildCategory, productGroup: ProductGroup | null) => void;
};

const ChildCategorySection: React.FC<ChildCategorySectionProps> = React.memo(({ selected, parent, category, onSelectChild }) => {
  const isChildNull = selected?.productGroup === null;
  const SelectAll = React.useCallback(() => {
    onSelectChild(parent, category, null);
  }, [onSelectChild, parent, category]);
  const onClickChildItem = React.useCallback(
    (product) => {
      onSelectChild(parent, category, product);
    },
    [onSelectChild, parent, category]
  );

  return (
    <div className={cs['child-section-container']}>
      <div className={merge(cs['child-all'], isChildNull ? cs['bg-linen'] : null)} onClick={SelectAll}>
        <p>{`全ての${category?.name}`}</p>
        <SelectCircle isSelected={isChildNull} />
      </div>
      <Divider bg={colors.gray20} />
      <div className={cs['child-item-wrapper']}>
        {category?.productGroups.map((product) => (
          <ChildItems selectedProduct={selected?.productGroup} product={product} onClick={onClickChildItem} key={product.name} />
        ))}
      </div>
    </div>
  );
});

ChildCategorySection.displayName = 'ChildCategorySection';

type ChildItemsProps = {
  selectedProduct: ProductGroup | null | undefined;
  product: ProductGroup;
  onClick: (productGroup: ProductGroup) => void;
};

const ChildItems: React.FC<ChildItemsProps> = React.memo(({ selectedProduct, product, onClick }) => {
  const onSelectProduct = React.useCallback(() => onClick(product), [product, onClick]);

  return (
    <div className={merge(cs['child-item-container'], selectedProduct === product ? cs['bg-linen'] : null)} onClick={onSelectProduct}>
      <p>{product.name}</p>
      <Spacer size={9} />
      <div>
        <SelectCircle isSelected={selectedProduct === product} />
      </div>
    </div>
  );
});

ChildItems.displayName = 'ChildItems';

export default CategorySelector;
