import * as React from 'react';

export type Props = {
  src: string;
  width: string;
  height: string;
  fit: 'cover' | 'contain';
  radius?: string;
  className?: string;
  style?: React.CSSProperties;
};

const LazyImage: React.FC<Props> = React.memo(({ src, width, height, fit, radius, className, style }) => {
  // mount時にIntersectionObserverに登録する
  const ref: React.RefCallback<HTMLImageElement> = React.useCallback((instance) => {
    if (instance) getOrCreateObserver().observe(instance);
  }, []);

  return (
    // IntersectionObserverを使う都合上、
    // データはattributeに保存するしかない
    //
    // "data-src" だとjquery.lazyloadと競合するため避ける
    <img
      ref={ref}
      className={className}
      data-image-src={src}
      style={{
        width,
        height,
        objectFit: fit,
        borderRadius: radius ?? 0,
        // 表示領域に入った時に1に設定する
        opacity: 0,
        ...style,
      }}
    />
  );
});

LazyImage.displayName = 'LazyImage';

let Observer: IntersectionObserver | null;

const getOrCreateObserver = (): IntersectionObserver => {
  if (Observer) {
    return Observer;
  }

  // create
  Observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // このObserverに登録されるのは↑で定義されている
          // コンポーネントのみなので、ここで受け取る値は
          // すべてHTMLImageElementであることが保証されている.
          const elem = entry.target as HTMLImageElement;
          const src = elem.dataset['imageSrc'];
          if (src) {
            elem.src = src;
            elem.style.opacity = '1';
          }

          // 一度画面内に入ったらobserveを停止する
          observer.unobserve(elem);
        }
      });
    },
    { rootMargin: '1000px 0px' }
  );

  return Observer;
};

export default LazyImage;
