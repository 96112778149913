import * as React from 'react';

import * as colors from 'libs/colors';
import Divider from 'components/atoms/Divider';
import { CombiSearchContext } from 'components/combi-search/Context';
import type { Relationship } from 'components/combi-search/types';
import Header from 'components/combi-search/pc/components/Header';
import Footer from 'components/combi-search/pc/components/Footer';
import RelationshipSelector from 'components/combi-search/pc/components/RelationshipSelector';

import Dropdown from './Dropdown';
import { SearchConditions } from './DropdownSwitcher';

type Props = {
  show: boolean;
  selected: SearchConditions;
  onClose: () => void;
  onSelect: (relationship: Relationship | null) => void;
};

const RelationshipSelectDropdown: React.FC<Props> = React.memo(({ show, selected, onClose, onSelect }) => {
  const [relationship, setRelationship] = React.useState<Relationship | null>(null);

  // 現在選択しているSceneから選択可能なRelationshipを計算する
  const { relationships } = React.useContext(CombiSearchContext);
  const selectableRelationships = React.useMemo(() => (selected.scene ? relationships.filter((r) => selected.scene?.relationshipCodes.includes(r.engName)) : relationships), [selected, relationships]);

  const onRelationshipSelect = React.useCallback(
    (relationship) => {
      setRelationship(relationship);
      onSelect(relationship);
    },
    [onSelect]
  );

  const onSubmit = React.useCallback(() => {
    onClose();
    onSelect(relationship);
  }, [onClose, onSelect, relationship]);

  const onClear = React.useCallback(() => {
    setRelationship(null);
    onSelect(null);
  }, [onSelect]);

  const onClickOutside = React.useCallback(() => {
    onClose();
    onSelect(relationship);
  }, [onClose, onSelect, relationship]);

  if (!show) {
    return null;
  } else {
    return (
      <Dropdown onClickOutside={onClickOutside}>
        <Header title="関係性" />
        <RelationshipSelector relationships={selectableRelationships} selectedRelationship={relationship} onSelect={onRelationshipSelect} />
        <Divider bg={colors.gray20} />
        <Footer onSubmit={onSubmit} onClear={onClear} />
      </Dropdown>
    );
  }
});

RelationshipSelectDropdown.displayName = 'RelationshipSelectDropdown';

export default RelationshipSelectDropdown;
