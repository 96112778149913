import * as React from 'react';

import type { Scene, Relationship, SelectedCategory, PriceRange, Detail } from 'components/combi-search/types';

import SceneSelectDropdown from '../SceneSelectDropdown';
import RelationshipSelectDropdown from '../RelationshipSelectDropdown';
import CategorySelectDropdown from '../CategorySelectDropdown';
import PriceRangeSelectDropdown from '../PriceRangeSelectDropdown';
import DetailSelectDropdown from '../DetailSelectDropdown';

export type SearchConditions = {
  scene: Scene | null;
  relationship: Relationship | null;
  category: SelectedCategory | null;
  detail: Detail;
  priceRange: PriceRange;
};

export type DropdownType = 'scene' | 'relationship' | 'category' | 'price-range' | 'detail';

type Props = {
  dropdown: DropdownType | null;
  selected: SearchConditions;
  onClose: () => void;
  onSelect: (updator: (prev: SearchConditions) => SearchConditions) => void;
};

const DropdownSwitcher: React.FC<Props> = React.memo(({ dropdown, onClose, onSelect, selected }) => (
  <>
    {/* シーン選択ドロップダウン */}
    <SceneSelectDropdown
      show={dropdown === 'scene'}
      onClose={onClose}
      onSelect={React.useCallback(
        (scene) => {
          onSelect((selected) => ({ ...selected, scene }));
        },
        [onSelect]
      )}
    />

    {/* 関係性選択ドロップダウン */}
    <RelationshipSelectDropdown
      show={dropdown === 'relationship'}
      selected={selected}
      onClose={onClose}
      onSelect={React.useCallback(
        (relationship) => {
          onSelect((selected) => ({ ...selected, relationship }));
        },
        [onSelect]
      )}
    />

    {/* カテゴリー選択ドロップダウン */}
    <CategorySelectDropdown
      show={dropdown === 'category'}
      onClose={onClose}
      onSelect={React.useCallback(
        (category) => {
          onSelect((selected) => ({ ...selected, category }));
        },
        [onSelect]
      )}
    />

    {/* Price Range選択ドロップダウン */}
    <PriceRangeSelectDropdown
      show={dropdown === 'price-range'}
      onClose={onClose}
      onSelect={React.useCallback(
        (priceRange) => {
          onSelect((selected) => ({ ...selected, priceRange }));
        },
        [onSelect]
      )}
    />

    {/* こだわり選択ドロップダウン */}
    <DetailSelectDropdown
      show={dropdown === 'detail'}
      onClose={onClose}
      onSelect={React.useCallback(
        (detail) => {
          onSelect((selected) => ({ ...selected, detail }));
        },
        [onSelect]
      )}
    />
  </>
));

DropdownSwitcher.displayName = 'DropdownSwitcher';

export default DropdownSwitcher;
