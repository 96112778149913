import * as React from 'react';

import { CombiSearchContext } from 'components/combi-search/Context';
import Spacer from 'components/atoms/Spacer';

import cs from './index.scss';
import ItemsRow from '../molecules/ItemsRow';

type Scene = {
  name: string;
  engName: string;
  imageUrl: string;
};

type Props = {
  selectedScene: Scene | null;
  onSelect: (scene: Scene | null) => void;
};

const SceneSelector: React.FC<Props> = React.memo(({ selectedScene, onSelect }) => {
  // シーンのデモデータ用意
  const { scenes } = React.useContext(CombiSearchContext);
  const [scenes1, scenes2, scenes3] = React.useMemo(() => [scenes.filter((_, i) => i % 3 === 0), scenes.filter((_, i) => i % 3 === 1), scenes.filter((_, i) => i % 3 === 2)], [scenes]);

  const onSelectScene = React.useCallback(
    (selected: Scene) => {
      onSelect(selected);
    },
    [onSelect]
  );

  return (
    <div className={cs['container']}>
      <div className={cs['overflow']}>
        <ItemsRow items={scenes1} selected={selectedScene} onSelect={onSelectScene} />
        <Spacer size={16} />
        <ItemsRow items={scenes2} selected={selectedScene} onSelect={onSelectScene} />
        <Spacer size={16} />
        <ItemsRow items={scenes3} selected={selectedScene} onSelect={onSelectScene} />
      </div>
    </div>
  );
});

SceneSelector.displayName = 'SceneSelector';

export default SceneSelector;
