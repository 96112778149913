// PC版のcombi-searchで作成したDropdownと共通する部分が多いのでいつかリファクタしたい
import * as React from 'react';

/*
 * ==========================
 * OutsideClickHandler
 * ==========================
 */
type Props = {
  onClickOutside: () => void;
};

const OutsideClickHandler: React.FC<Props> = React.memo(({ onClickOutside, children }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // 他で参照することの無い関数なので、useEffect内で定義する
    const handleOutsideClick = (e: Event) => {
      if (ref.current && e.target instanceof Node && !ref.current.contains(e.target)) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onClickOutside]);

  return <div ref={ref}>{children}</div>;
});

OutsideClickHandler.displayName = 'OutsideClickHandler';

export default OutsideClickHandler;
