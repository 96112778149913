import * as React from 'react';

import * as colors from 'libs/colors';
import { merge } from 'libs/classname';
import Spacer from 'components/atoms/Spacer';
import { ChevronDownIcon } from 'components/icons';

import cs from './ConditionInput.scss';

type InputContainerProps = {
  isColored: boolean;
  onClick: () => void;
};

export const InputContainer: React.FC<InputContainerProps> = React.memo(({ isColored, onClick, children }) => {
  const className = merge(cs['input-container'], isColored ? cs['bg-linen'] : null);

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
});

InputContainer.displayName = 'InputContainer';

type InputTitleProps = {
  title: string;
  isColored: boolean;
};

export const InputTitle: React.FC<InputTitleProps> = React.memo(({ title, isColored }) => (
  <div className={cs['input-title']}>
    <p className={isColored ? cs['p-orange'] : cs['p-gray']}>{title}</p>
    <Spacer size={11} />
    <ChevronDownIcon size="17px" color={isColored ? colors.softOrange : colors.gray90} />
  </div>
));

InputTitle.displayName = 'InputTitle';

type InputSelected = {
  isSelected: boolean;
};

export const InputItem: React.FC<InputSelected> = React.memo(({ isSelected, children }) => {
  const className = merge(cs['input-item'], isSelected ? cs['selected'] : cs['unselected']);

  return <div className={className}>{children}</div>;
});

InputItem.displayName = 'InputSelected';
