import * as React from 'react';

import * as colors from 'libs/colors';
import { fmtNum } from 'libs/formatter';
import Spacer from 'components/atoms/Spacer';
import { CarretDownIcon } from 'components/icons';

import cs from './index.scss';

type Props = {
  priceRange: PriceRange;
  maxPrice: number;
  step: number;
  minRange: number;
  onChange: (range: PriceRange) => void;
};

type PriceRange = {
  lower: number;
  upper: number | null;
};

const PriceRangeSelector: React.FC<Props> = React.memo(({ priceRange, maxPrice, step, minRange, onChange }) => (
  <div className={cs['container']}>
    <div className={cs['price-range']}>
      <div className={cs['lower-price']}>
        <div className={cs['label']}>最低価格</div>
        <Spacer size={8} />
        <LowerPricePullDown priceRange={priceRange} maxPrice={maxPrice} step={step} minRange={minRange} onSelect={onChange} />
      </div>
      <div className={cs['tilde']}>~</div>
      <div className={cs['upper-price']}>
        <div className={cs['label']}>最高価格</div>
        <Spacer size={8} />
        <UpperPricePullDown priceRange={priceRange} maxPrice={maxPrice} step={step} minRange={minRange} onSelect={onChange} />
      </div>
    </div>
  </div>
));

PriceRangeSelector.displayName = 'PriceRangeSelector';

type LowerPricePullDownProps = {
  priceRange: PriceRange;
  maxPrice: number;
  step: number;
  minRange: number;
  onSelect: (priceRange: PriceRange) => void;
};

const LowerPricePullDown: React.FC<LowerPricePullDownProps> = React.memo(({ priceRange, maxPrice, step, minRange, onSelect }) => {
  const options = React.useMemo(() => {
    // 現在の最高価格（"上限無し"ならmaxPriceと同じ）
    const ceilPrice = priceRange.upper ? priceRange.upper : maxPrice;
    // 価格リストを作成する
    // 最高価格から`minRange`円以下を選ぶことができる（最小幅が存在する）
    const priceList = Array.from(new Array(Math.floor(ceilPrice / step)))
      .map((_, i) => i * step)
      .filter((price) => price <= ceilPrice - minRange);
    // 価格リストからoptionを作成
    const options = priceList.map((price) => (
      <option key={price} value={price}>
        {fmtNum(price)}
      </option>
    ));
    return options;
  }, [priceRange, maxPrice, step, minRange]);

  const onChangeLowerPrice = React.useCallback(
    (lower: string) => {
      const newPriceRange = {
        lower: Number(lower),
        upper: priceRange.upper,
      };
      onSelect(newPriceRange);
    },
    [priceRange, onSelect]
  );

  return (
    <div className={cs['relative']}>
      <select value={priceRange.lower} onChange={(e) => onChangeLowerPrice(e.target.value)} className={cs['price-pull-down']}>
        {options}
      </select>
      <CarretDownIcon className={cs['icon']} color={colors.gray90} size="25px" fill strokeWidth="0" />
    </div>
  );
});

LowerPricePullDown.displayName = 'LowerPricePullDown';

type UpperPricePullDownProps = {
  priceRange: PriceRange;
  maxPrice: number;
  step: number;
  minRange: number;
  onSelect: (priceRange: PriceRange) => void;
};

const UpperPricePullDown: React.FC<UpperPricePullDownProps> = React.memo(({ priceRange, maxPrice, step, minRange, onSelect }) => {
  const options = React.useMemo(() => {
    // 価格リストを作成する
    // 現在の最低価格から`minRange`円以上の価格を選ぶことができる（最小幅が存在する）
    const priceList = Array.from(new Array(Math.floor(maxPrice / step)))
      .map((_, i) => i * step)
      .filter((price) => price >= priceRange.lower + minRange);
    // 価格リストからoptionを作成
    const options = priceList.map((price) => (
      <option key={price} value={price}>
        {fmtNum(price)}
      </option>
    ));
    return options;
  }, [priceRange, maxPrice, step, minRange]);

  const onChangeUpperPrice = React.useCallback(
    (upper: string) => {
      const newPriceRange = {
        lower: priceRange.lower,
        upper: Number(upper) ?? null,
      };
      onSelect(newPriceRange);
    },
    [priceRange, onSelect]
  );

  return (
    <div className={cs['relative']}>
      <select value={priceRange.upper ?? 'no-upper'} onChange={(e) => onChangeUpperPrice(e.target.value)} className={cs['price-pull-down']}>
        {options}
        <option value={'no-upper'}>上限なし</option>
      </select>
      <CarretDownIcon className={cs['icon']} color={colors.gray90} size="25px" fill strokeWidth="0" />
    </div>
  );
});

UpperPricePullDown.displayName = 'UpperPricePullDown';

export default PriceRangeSelector;
