import * as React from 'react';

import Spacer from 'components/atoms/Spacer';

import ItemCell from '../ItemCell';
import cs from './index.scss';

type Item = {
  name: string;
  imageUrl: string;
};

type ItemsRowProps<T> = {
  items: T[];
  selected: T | null;
  onSelect: (item: T) => void;
};

const ItemsRow = <T extends Item>({ items, selected, onSelect }: ItemsRowProps<T>): JSX.Element => (
  <div className={cs['items-row']}>
    {items.map((item, i) => (
      <React.Fragment key={item.name}>
        {i !== 0 && <Spacer size={15} />}
        <div className={cs['item-cell-wrapper']}>
          <ItemCell item={item} selected={item.name === selected?.name} onSelected={() => onSelect(item)} />
        </div>
      </React.Fragment>
    ))}
  </div>
);

export default ItemsRow;
