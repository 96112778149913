import * as React from 'react';
/* eslint-disable-next-line css-modules/no-unused-class */
import classes from './Box.scss';
import { Color } from 'libs/colors';

export type Props = {
  // default is 100%.
  width?: string;
  padding?: string;
  bg?: Color;
  // デフォルトでは縦方向にアイテムが並ぶ
  horizontal?: boolean;
  align?: 'stretch' | 'center' | 'baseline' | 'start';
  // justify-contentのパラメータ
  // 未指定の場合はjustify-content: normalが指定される
  space?: 'between' | 'evenly';
  // 改行をするかどうか
  wrap?: boolean;
  border?: Color;
  radius?: number;
  onClick?: () => void;
  className?: string;
};

// デフォルトでは縦方向にアイテムが並ぶ
const Box = React.memo(
  React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, ref) => {
    const classNames = [classes['box']];
    classNames.push(classes[props.horizontal ? 'row' : 'column']);
    classNames.push(classes[`align-${props.align || 'normal'}`]);
    classNames.push(classes[props.wrap ? 'wrap' : 'nowrap']);
    classNames.push(classes[`space-${props.space || 'normal'}`]);
    if (props.className) classNames.push(props.className);
    const className = classNames.join(' ');

    const style: React.CSSProperties = {};
    if (props.width) style.width = props.width;
    if (props.padding) style.padding = props.padding;
    if (props.bg) style.backgroundColor = props.bg.hex;
    if (props.border) style.border = `1px solid ${props.border.hex}`;
    if (props.radius) style.borderRadius = props.radius;

    return (
      <div className={className} style={style} onClick={props.onClick} ref={ref}>
        {props.children}
      </div>
    );
  })
);
Box.displayName = 'Box';

/*
 * BoxItem
 */
export type BoxItemProps = {
  scale?: number;
  className?: string;
  onClick?: () => void;
};

export const BoxItem: React.FC<BoxItemProps> = React.memo(({ scale, className: passedClassName, onClick, children }) => {
  const classNames = [classes['box-item']];
  if (passedClassName) classNames.push(passedClassName);
  const className = classNames.join(' ');

  const style: React.CSSProperties = {};
  if (scale) style.flexGrow = scale;

  return (
    <div className={className} style={style} onClick={onClick}>
      {children}
    </div>
  );
});
BoxItem.displayName = 'BoxItem';

export default Box;
