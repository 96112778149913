import type { CombiSearchData, Scene, Relationship, SelectedCategory, Detail, PriceRange } from '../types';
import { availableAges } from './ages';

export type SearchConditions = {
  scene: Scene | null;
  relationship: Relationship | null;
  category: SelectedCategory | null;
  detail: Detail;
  priceRange: PriceRange;
};

export const emptySearchConditions = {
  scene: null,
  relationship: null,
  category: null,
  detail: {
    keyword: '',
    delivery: null,
    express: false,
    age: null,
    subOptionCategories: [],
    coupons: [],
    includeOutOfStock: false,
    isTanpProducts: false,
    onlyIncludeEGiftProducts: false,
  },
  priceRange: {
    lower: 0,
    upper: null,
  },
};

// 検索条件でヒットする商品の数を取得する
export const fetchItemCount = (cond: SearchConditions): Promise<number> => {
  const { path, query } = searchConditionToURL(cond);
  return fetch(path + queryToString(query), {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  })
    .then((res) => res.text())
    .then((res) => parseInt(res));
};

// 相対パスのURLを返す
// e.g. /products/scene-birthday/relationship-boyfriend?delivery=true
export const searchConditionToURL = (cond: SearchConditions): { path: string; query: Record<string, string> } => {
  let path = '/products';
  if (cond.scene) {
    path += `/scene-${cond.scene.engName}`;
  }
  if (cond.relationship) {
    path += `/relationship-${cond.relationship.engName}`;
  }
  if (cond.detail.age) {
    path += `/age-${cond.detail.age.engName}`;
  }
  if (cond.category) {
    path += `/parent-${cond.category.parent.engName}`;
    if (cond.category.category) {
      path += `/category-${cond.category.category.engName}`;
    }
    if (cond.category.productGroup) {
      path += `/product_group-${cond.category.productGroup.engName}`;
    }
  }

  const query: Record<string, string> = {};
  if (cond.detail.keyword) {
    query['keyword'] = encodeURIComponent(cond.detail.keyword);
  }
  if (cond.detail.delivery) {
    const { prefecture, date } = cond.detail.delivery;
    query['delivery_prefecture_id'] = `${prefecture.id}`;
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    query['delivery_date'] = `${y}-${m}-${d}`;
  }
  if (cond.detail.express) {
    query['delivery'] = 'true';
  }
  const coupons = cond.detail.coupons.map((coupon) => coupon.id).join(',');
  if (coupons) {
    query['coupons'] = coupons;
  }
  const subOptionCategoryIds = cond.detail.subOptionCategories.map((subOptionCategory) => subOptionCategory.id).join(',');
  if (subOptionCategoryIds) {
    query['sub_option_category_ids'] = `${subOptionCategoryIds}`;
  }
  if (cond.detail.includeOutOfStock) {
    query['include_out_of_stock'] = '1';
  }
  if (cond.detail.isTanpProducts) {
    query['is_tanp_products'] = '1';
  }
  if (cond.detail.onlyIncludeEGiftProducts) {
    query['only_include_e_gift_products'] = '1';
  }
  if (cond.priceRange.lower !== 0) {
    query['min_price'] = `${cond.priceRange.lower}`;
  }
  if (cond.priceRange.upper !== null) {
    query['max_price'] = `${cond.priceRange.upper}`;
  }

  return {
    path,
    query,
  };
};

export const queryToString = (query: Record<string, string>): string => {
  let string = Object.entries(query)
    .map((entry) => entry.join('='))
    .join('&');

  if (string.length !== 0) {
    string = `?${string}`;
  }

  return string;
};

// 現在のパスから現在の検索条件を取得する
export const searchConditionFromURL = (path: string, query: string, data: CombiSearchData): SearchConditions => {
  const sceneRegex = /\/scene-([^/]+)/;
  const sceneStr = extractMatch(sceneRegex, path);
  const scene = data.scenes.find(({ engName }) => engName === sceneStr) || null;

  const relRegex = /\/relationship-([^/]+)/;
  const relStr = extractMatch(relRegex, path);
  const relationship = data.relationships.find(({ engName }) => engName === relStr) || null;

  const parentRegex = /\/parent-([^/]+)/;
  const parentStr = extractMatch(parentRegex, path);
  const parent = data.parents.find(({ engName }) => engName === parentStr) || null;

  const categoryRegex = /\/category-([^/]+)/;
  const categoryStr = extractMatch(categoryRegex, path);
  const category = parent?.categories.find(({ engName }) => engName === categoryStr) || null;

  const productGroupRegex = /\/product_group-([^/]+)/;
  const productGroupStr = extractMatch(productGroupRegex, path);
  const productGroup = category?.productGroups.find((pg) => pg.engName === productGroupStr) || null;

  const ageRegex = /\/age-([^/]+)/;
  const ageStr = extractMatch(ageRegex, path);
  const age = availableAges.find((age) => age.engName === ageStr) || null;

  const keywordRegex = /[&?]keyword=([^&]+)/;
  const encodedKeyword = extractMatch(keywordRegex, query) || '';
  const keyword = window.decodeURIComponent(encodedKeyword);

  const deliveryDateRegex = /[&?]delivery_date=(\d{4})-(\d{1,2})-(\d{1,2})/;
  const deliveryDateStrs = extractMatch3(deliveryDateRegex, query);
  let deliveryDate = null;
  if (deliveryDateStrs !== null) {
    const [y, m, d] = deliveryDateStrs;
    deliveryDate = new Date(parseInt(y), parseInt(m) - 1, parseInt(d));
  }

  const expressRegex = /[&?]delivery=([^&]+)/;
  const express = extractMatch(expressRegex, query) === 'true';

  const deliveryPrefectureIdRegex = /[&?]delivery_prefecture_id=(\d+)/;
  const deliveryPrefectureIdStr = extractMatch(deliveryPrefectureIdRegex, query);
  let deliveryPrefectureId: number | null = null;
  if (deliveryPrefectureIdStr !== null) {
    deliveryPrefectureId = parseInt(deliveryPrefectureIdStr);
  }

  let delivery = null;
  if (deliveryDate !== null && deliveryPrefectureId !== null) {
    delivery = {
      prefecture: data.deliveryConditions.find((d) => d.prefecture.id === deliveryPrefectureId)!.prefecture,
      date: deliveryDate,
    };
  }

  const couponsRegex = /[&?]coupons=([\d,]+)/;
  const couponIds = extractMatch(couponsRegex, query)?.split(',') || [];
  const coupons = data.coupons.filter(({ id }) => couponIds.includes(`${id}`));

  const subOptionCategoryRegex = /[&?]sub_option_category_ids=([\d,]+)/;
  const subOptionCategoryIds = extractMatch(subOptionCategoryRegex, query)?.split(',') || [];
  const subOptionCategories = data.subOptionCategories.filter(({ id }) => subOptionCategoryIds.includes(`${id}`));

  const includeOutOfStockRegex = /[&?]include_out_of_stock=1/;
  const includeOutOfStock = includeOutOfStockRegex.test(query);

  const isTanpProductsRegex = /[&?]is_tanp_products=1/;
  const isTanpProducts = isTanpProductsRegex.test(query);

  const onlyIncludeEGiftProductsRegex = /[&?]only_include_e_gift_products=1/;
  const onlyIncludeEGiftProducts = onlyIncludeEGiftProductsRegex.test(query);

  const lowerPriceRegex = /[&?]min_price=(\d+)/;
  const lowerPriceStr = extractMatch(lowerPriceRegex, query) || '0';
  const lowerPrice = parseInt(lowerPriceStr);

  const upperPriceRegex = /[&?]max_price=(\d+)/;
  const upperPriceStr = extractMatch(upperPriceRegex, query);
  const upperPrice = upperPriceStr ? parseInt(upperPriceStr) : null;

  return {
    scene,
    relationship,
    category: parent
      ? {
          parent,
          category,
          productGroup,
        }
      : null,
    detail: {
      keyword,
      delivery,
      express,
      age,
      coupons,
      subOptionCategories,
      includeOutOfStock,
      isTanpProducts,
      onlyIncludeEGiftProducts,
    },
    priceRange: {
      lower: lowerPrice,
      upper: upperPrice,
    },
  };
};

const extractMatch = (regex: RegExp, str: string): string | null => {
  const result = regex.exec(str);
  if (result === null) {
    return null;
  }
  return result[1];
};

const extractMatch3 = (regex: RegExp, str: string): [string, string, string] | null => {
  const result = regex.exec(str);
  if (result === null) {
    return null;
  }
  return [result[1], result[2], result[3]];
};
