import * as React from 'react';

import * as colors from 'libs/colors';
import Divider from 'components/atoms/Divider';
import type { SelectedCategory } from 'components/combi-search/types';
import CategorySelector from 'components/combi-search/pc/components/CategorySelector';
import Footer from 'components/combi-search/pc/components/Footer';

import Dropdown from './Dropdown';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (category: SelectedCategory | null) => void;
};

const CategorySelectDropdown: React.FC<Props> = React.memo(({ show, onClose, onSelect }) => {
  const [selected, setSelected] = React.useState<SelectedCategory | null>(null);
  const onCategorySelect = React.useCallback(
    (category) => {
      setSelected(category);
      onSelect(category);
    },
    [onSelect]
  );

  const onSubmit = React.useCallback(() => {
    onClose();
    onSelect(selected);
  }, [onClose, onSelect, selected]);

  const onClear = React.useCallback(() => {
    setSelected(null);
    onSelect(null);
  }, [onSelect]);

  const onClickOutside = React.useCallback(() => {
    onClose();
    onSelect(selected);
  }, [onClose, onSelect, selected]);

  if (!show) {
    return null;
  } else {
    return (
      <Dropdown onClickOutside={onClickOutside}>
        <CategorySelector selected={selected} onSelect={onCategorySelect} />
        <Divider bg={colors.gray20} />
        <Footer onSubmit={onSubmit} onClear={onClear} />
      </Dropdown>
    );
  }
});

CategorySelectDropdown.displayName = 'CategorySelectDropdown';

export default CategorySelectDropdown;
